import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "@frontend/app/store/reducers";
import "@frontend/app/styles/default/theme.less";

import ErrorPage from "../pages/ErrorPage";

const mapState = ({ viewData: { error }, appConfig: { S3AssetsURL } }: RootState) => ({
  error,
  S3AssetsURL,
});

const DefaultWhitelabelLayout: React.FC = () => {
  const { error, S3AssetsURL } = useSelector(mapState, shallowEqual);
  const notImplementedError = new Error() as any;
  notImplementedError.code = "UNSUPPORTED_THEME_ERROR";

  const iconURL = `${S3AssetsURL}/common/notFound-magnifier.svg`;

  return (
    <div className="main default-layout default-layout-error">
      <div className="body" suppressHydrationWarning>
        <div className="body__section text-align-center default-error-page">
          <img className="error-icon" src={iconURL} alt="Error" />
          <ErrorPage error={error ?? notImplementedError} />
        </div>
      </div>
    </div>
  );
};

export default DefaultWhitelabelLayout;
